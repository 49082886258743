import React from "react"
import TemplatePageGuide  from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide30/dataPageGuide30"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
      Cómo hacer un plan comercial en 8 pasos 
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/como-hacer-plan-comercial/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Te ayudamos a crear un plan comercial para tu empresa para que puedas implementar mejor tus campañas de telemarketing."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Cómo hacer un plan comercial en 8 pasos "
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/como-hacer-plan-comercial/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Guias/og-imgGuia30.jpg`}
      />
      <meta
        property="og:description"
        content="Te ayudamos a crear un plan comercial para tu empresa para que puedas implementar mejor tus campañas de telemarketing."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Telemarketing"
      location={location}
      typeGuide="guide30"
      clickHereHref="https://beexcc.com/blog/noticias-atencion-al-cliente/"
      data={data}
    />
  </div>
)

export default IndexPage
